export const FirebaseConfig = {
	"projectId": "redoosmvp",
	"appId": "1:673671207760:web:9155797d73ea016025aa55",
	"databaseURL": "https://redoosmvp-default-rtdb.firebaseio.com",
	"storageBucket": "redoosmvp.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyCDWdbbYYcTNJWp5m5Q8Bk4YEBaC81Nc04",
	"authDomain": "redoosmvp.firebaseapp.com",
	"messagingSenderId": "673671207760",
	"measurementId": "G-YSWR6TKB28"
};